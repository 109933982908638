<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('assets_info.create')" to="/accounting/assets-info/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>{{ $t('assets_info.add_assets_info')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row pb-5">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('assets_info.name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('assets_info.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('assets_info.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="employee_id">{{$t('assets_info.employee')}}</label>
                            <!-- <select name="" id="employee_id" v-model="filters.employee_id" class="custom-select">
                                <option v-for="row in employees" :value="row.id" :key="row.id">
                                    {{ row.full_name }}
                                </option>
                            </select> -->
                            <multiselect v-model="employee"
                                :placeholder="$t('assets_info.employee')"
                                label="full_name"
                                track-by="id"
                                :options="employees"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getEmployees($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="status">{{$t('status')}}</label>
                            <select name="" id="status" v-model="filters.status" class="custom-select">
                                <option v-for="row in status_list" :value="row.id" :key="row.id">
                                    {{ row.title }}
                                </option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    
                    <template slot="assets_info" slot-scope="props">
                        <div>
                             <div><span>{{$t('assets_info.id')}}: </span><span>{{ props.row.id}}</span></div>
                             <div><span>{{$t('assets_info.name')}}: </span><span>{{ props.row.name}}</span></div>
                             <div><span>{{$t('assets_info.account_name')}}: </span><span>{{ props.row.account_name}}</span></div>
                        </div>
                    </template>
                    
                    <template slot="purchase_info" slot-scope="props">
                        <div>
                            <span>({{ props.row.status_name }})</span>
                        </div>
                         <div>
                            <span>{{$t('assets_info.amount')}}: </span><span>{{ props.row.purchase_amount}}</span>
                        </div>
                        <div><span>{{$t('assets_info.currency')}}: </span><span>{{ props.row.purchase_currency_name}}</span></div>
                    </template>
                    <template slot="status" slot-scope="props">
                        <button v-if="$can('assets_info.update')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-btn icon v-if="$can('assets_info.update')" color="pink" :to="`/accounting/assets-info/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
                        </v-btn>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('assets_info.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <!-- <div>
                            <b-dropdown right :text="$t('more_actions')">
                                <b-dropdown-item @click="showDetails(props.row.id)">{{$t('assets_info.details')}}</b-dropdown-item>
                            </b-dropdown>
                        </div> -->
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                    :status-list="status_list"
                    :url="routeChangeStatus"
                    :current-id="innerId"
                    :current-status="statusId"
                    @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-assets_info",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'accounting/assets-info',
                routeChangeStatus: 'accounting/asset-info/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: null,
                    from_date: null,
                    to_date: null,
                    employee_id: null,
                    status: null,
                },
                columns: ['assets_info','purchase_info','status', 'actions'],
                data: [],

                employees: [],
                employee: null,
                innerId: null,
                statusId: null,
                status_list :[],
            }
        },
        watch: {
            employee: function (val) {
                if (val) {
                    this.filters.employee_id = val.id;
                } else {
                    this.filters.employee_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        assets_info: that.$t('assets_info.assets_info'),
                        purchase_info: that.$t('assets_info.purchase_info'),
                        status: that.$t('assets_info.status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.assets_info")}]);

            this.getStatusList();
        },
        methods: {
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.employee_id = null;
                this.filters.status = null;
                this.employee = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status_assets").then((response) => {
                    this.status_list = response.data.data;
                });
            },

            getEmployees(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/employees`,{params:{filter: filter}}).then((response) => {
                        this.employees = response.data.data;
                    });
                }else{
                    this.employees = [];
                }
            },

            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            showDetails(id){
                this.$router.push({name: 'assets_info.details', params:{id: id}});
            }
        },
    };
</script>
